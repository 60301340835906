.login-buttons {
    display: flex;
    justify-content: space-between;

    &__left {

    }

    &__right {
        > button {
            margin-left: 5px;
        }
    }
}